<template>
  <div>
    <p class="collection">
      <el-button class="el-buttons" :type="article" @click="WDfollow">我关注的人</el-button>
      <el-button :type="btnfla" @click="flash">我收藏的内容</el-button>
    </p>
    <!-- 资源交易 -->
    <ul class="xl">
      <li class="plcont" v-for="(item, index) in Collectionlist" :key="index">
        <ul>
          <img class="img" v-if="item.img" v-show="show_t" :src="item.img" />
          <li class="name">
            <span v-show="show"></span>
            <span
              v-show="show"
              class="chakans"
              @click="ZYchakan(item.beKoleksi)"
            >{{ item.username }}</span>
            <span
              v-if="show_t"
              class="chakans"
              @click="ZZZZ(item.beConcernManS)"
            >{{ item.username }}</span>
            <span class="num">{{ item.type }}</span>
          </li>
          <li class="user">
            <span class="time" v-show="show_t">{{ item.works }}</span>
            <span class="time" v-show="show_t">{{ item.beConcernMan }}</span>
            <span class="dele" v-show="show_t">
              <el-button type="text" @click="ZYfollow(item.id)">取消关注</el-button>
            </span>
            <span class="dele" v-show="show">
              <el-button type="text" @click="ZYCollection(item.id)">取消收藏</el-button>
            </span>
          </li>
        </ul>
      </li>
    </ul>

    <!-- 知识头条 -->
    <ul class="xl">
      <li class="plcont" v-for="(item, index) in Collectionlists" :key="index">
        <ul>
          <img class="img" v-if="item.img" v-show="show_t" :src="item.img" />
          <li class="name">
            <span v-show="show"></span>
            <span v-show="show" class="chakans" @click="XXchakan(item.article)">{{ item.username }}</span>
            <span
              v-if="show_t"
              class="chakans"
              @click="XXZZ(item.beConcernManS)"
            >{{ item.username }}</span>
            <span class="num">{{ item.type }}</span>
          </li>
          <li class="user">
            <span class="time" v-show="show_t">{{ item.works }}</span>
            <span class="time" v-show="show_t">{{ item.beConcernMan }}</span>
            <span class="dele" v-show="show_t">
              <el-button type="text" @click="XSfollow(item.id)">取消关注</el-button>
            </span>
            <span class="dele" v-show="show">
              <el-button type="text" @click="XSCollection(item.id)">取消收藏</el-button>
            </span>
          </li>
        </ul>
      </li>
    </ul>

    <!-- 智能问答 -->
    <ul class="xl">
      <li class="plcont" v-for="(item, index) in Collectionlisted" :key="index">
        <ul>
          <img class="img" v-if="item.img" v-show="show_t" :src="item.img" />
          <li class="name">
            <span v-show="show"></span>
            <span v-show="show" class="chakans" @click="ZZchakan(item.question)">{{ item.username }}</span>
            <span
              v-if="show_t"
              class="chakans"
              @click="gzZZ(item.beconcernManS)"
            >{{ item.username }}</span>
            <span class="num">{{ item.type }}</span>
          </li>
          <li class="user">
            <span class="time" v-show="show_t">{{ item.works }}</span>
            <span class="time" v-show="show_t">{{ item.beConcernMan }}</span>
            <span class="dele" v-show="show_t">
              <el-button type="text" @click="ZNfollow(item.id)">取消关注</el-button>
            </span>
            <span class="dele" v-show="show">
              <el-button type="text" @click="ZNCollection(item.id)">取消收藏</el-button>
            </span>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
import gql from "graphql-tag";
import { INSERT, QUERY, DELETE, QUERYED } from "@/services/dao.js";
export default {
  data() {
    return {
      Collectionlist: [], //资源交易
      Collectionlists: [], //知识头条
      Collectionlisted: [], //智能问答
      content: "",
      time: "",
      source: "",
      article: "primary",
      btnfla: "",
      show: false,
      show_t: true
    };
  },
  created() {
    ///资源交易
    this.inits(); ///关注
    // 知识头条
    // this.learning();
    ///智能问答
    // this.intelligence();
    // 知识头条
    // this.learning();
    ///智能问答
    // this.intelligence();
  },
  methods: {
    async gzZZ(id) {
      this.$router.push({
        path: "/answer-list",
        query: {
          id: id
        }
      });
    },
    async ZZZZ(id) {
      this.$router.push({
        path: "/Reexpert",
        query: {
          id: id
        }
      });
    },
    async XXZZ(id) {
      this.$router.push({
        path: "/articleed",
        query: {
          id: id
        }
      });
    },
    //查看详情
    async ZYchakan(id) {
      this.$router.push({
        path: "/Resource_details",
        query: {
          id: id
        }
      });
    },
    //查看详情
    async XXchakan(id) {
      this.$router.push({
        path: "/articleDetail",
        query: {
          id: id
        }
      });
    },
    //查看详情
    async ZZchakan(id) {
      this.$router.push({
        path: "/writeanswer",
        query: {
          id: id
        }
      });
    },
    ////资源交易的取消关注
    async ZYfollow(id) {
      let info = await DELETE(
        "post",
        "",
        '  delete_ConcernMan(where: {type: {_eq: "2"}, id: {_eq: ' +
          id +
          "}}) {   affected_rows  }"
      );
      if (info.data.delete_ConcernMan.affected_rows == 1) {
        this.$message({
          message: "取消关注成功",
          type: "success"
        });
        this.init(); //刷新
      }
    },
    //////资源交易的取消收藏
    async ZYCollection(id) {
      let info = await DELETE(
        "post",
        "",
        ' delete_Koleksi(where: {type: {_eq: "2"}, id: {_eq: ' +
          id +
          "}}) {   affected_rows  }"
      );
      if (info.data.delete_Koleksi.affected_rows == 1) {
        this.$message({
          message: "取消收藏成功",
          type: "success"
        });
        this.flash(); //刷新
      }
    },
    /////知识头条的取消关注
    async XSfollow(id) {
      let info = await DELETE(
        "post",
        "",
        "  delete_TtConcernMan(where: {id: {_eq: " +
          id +
          "}}) {   affected_rows  }"
      );
      if (info.data.delete_TtConcernMan.affected_rows == 1) {
        this.$message({
          message: "取消关注成功",
          type: "success"
        });
        this.learning(); //刷新
      }
    },
    ////知识头条的取消收藏
    async XSCollection(id) {
      let info = await DELETE(
        "post",
        "",
        "  delete_TtCollect(where: {id: {_eq: " +
          id +
          "}}) {   affected_rows  }"
      );
      if (info.data.delete_TtCollect.affected_rows == 1) {
        this.$message({
          message: "取消收藏成功",
          type: "success"
        });
        this.flash(); //刷新
      }
    },
    //////智能问答的取消关注
    async ZNfollow(id) {
      let info = await DELETE(
        "post",
        "",
        "  delete_wdConcernMan(where: {id: {_eq: " +
          id +
          "}}) {   affected_rows  }"
      );
      if (info.data.delete_wdConcernMan.affected_rows == 1) {
        this.$message({
          message: "取消关注成功",
          type: "success"
        });
        this.intelligence(); //刷新
      }
    },
    //////智能问答的取消收藏
    async ZNCollection(id) {
      let info = await DELETE(
        "post",
        "",
        "  delete_WdCollect(where: {id: {_eq: " +
          id +
          "}}) {   affected_rows  }"
      );
      if (info.data.delete_WdCollect.affected_rows == 1) {
        this.$message({
          message: "取消收藏成功",
          type: "success"
        });
        this.flash(); //刷新
      }
    },
    ////关注
    async inits() {
      //资源交易
      this.init(); //
      // 知识头条
      this.learning();
      ///智能问答
      this.intelligence();
    },
    ///我的关注
    async WDfollow() {
      this.inits();
    },
    ///////////关注////////////////////////
    async init() {
      this.show = false;
      this.show_t = true;
      this.Collectionlist = [];
      this.article = "primary";
      this.btnfla = "";
      // 资源交易
      let res = await QUERYED(
        "post",
        "",
        "ZYConcernMan(where: {concernMan: {_eq: " +
          this.$store.state.userinfo.id +
          "}}){    id    beConcernMan}"
      );
      for (let i = 0; i < res.data.ZYConcernMan.length; i++) {
        let info = await QUERYED(
          "post",
          "",
          "ZYUser(where: {id: {_eq: " +
            res.data.ZYConcernMan[i].beConcernMan +
            "}}){    id    circleurl  works  username}"
        );
        ///被多少人关注
        let data1 = await QUERYED(
          "post",
          "",
          ' ZYConcernMan_aggregate(where: {beConcernMan: {_eq: "' +
            res.data.ZYConcernMan[i].beConcernMan +
            '"}}) {  aggregate {   count    } }'
        );
        let resdoc = await QUERYED(
          "post",
          "",
          "ZYDocument_ALL(where: {user: {_eq: " +
            res.data.ZYConcernMan[i].beConcernMan +
            "}}){    name}"
        );
        this.Collectionlist.push({
          id: res.data.ZYConcernMan[i].id,
          beConcernManS: res.data.ZYConcernMan[i].beConcernMan,
          img: this.api.LoginURL.concat(info.data.ZYUser[0].circleurl),
          works: "发表" + resdoc.data.ZYDocument_ALL.length + "篇资源",
          beConcernMan:
            "被" + data1.data.ZYConcernMan_aggregate.aggregate.count + "人关注",
          username: info.data.ZYUser[0].username,
          type: "资源交易"
        });
      }
      console.log(this.Collectionlist);
    },
    ////知识头条
    async learning() {
      this.Collectionlists = [];
      let data = await QUERYED(
        "post",
        "",
        "TTConcernMan(where: {concernMan: {_eq: " +
          this.$store.state.userinfo.id +
          "}}){    id    beConcernMan }"
      ); ///关注表
      for (let i = 0; i < data.data.TTConcernMan.length; i++) {
        let info = await QUERYED(
          "post",
          "",
          "PCUsers(where: {id: {_eq: " +
            data.data.TTConcernMan[i].beConcernMan +
            "}}){    id img    name}"
        );
        ///发表文章数
        let info1 = await QUERYED(
          "post",
          "",
          "  Article_aggregate(where: {userid: {_eq: " +
            data.data.TTConcernMan[i].beConcernMan +
            "}}) {  aggregate {     count   }  }"
        );
        ////被多少人关注
        ///被多少人关注
        let data1 = await QUERYED(
          "post",
          "",
          ' TtConcernMan_aggregate(where: {beConcernMan: {_eq: "' +
            data.data.TTConcernMan[i].beConcernMan +
            '"}}) {  aggregate {   count    } }'
        );
        this.Collectionlists.push({
          id: data.data.TTConcernMan[i].id,
          beConcernManS: data.data.TTConcernMan[i].beConcernMan,
          img: this.api.LoginURL.concat(info.data.PCUsers[0].img),
          works:
            "发表" + info1.data.Article_aggregate.aggregate.count + "篇文章",
          beConcernMan:
            "被" + data1.data.TtConcernMan_aggregate.aggregate.count + "人关注",
          username: info.data.PCUsers[0].name,
          type: "知识头条"
        });
      }
      console.log(this.Collectionlists);
    },
    ////智能问答
    async intelligence() {
      this.Collectionlisted = [];
      let data = await QUERYED(
        "post",
        "",
        "WDUserConcern(where: {concernMan: {_eq: " +
          this.$store.state.userinfo.id +
          "}}){    wdconcernmanid    beconcernMan }"
      );
      for (let i = 0; i < data.data.WDUserConcern.length; i++) {
        //alert( data.data.WDUserConcern[i].beconcernMan)
        let info = await QUERYED(
          "post",
          "",
          "PCUsers(where: {id: {_eq: " +
            data.data.WDUserConcern[i].beconcernMan +
            "}}){    id img    name}"
        );
        ///发表回答数
        let info1 = await QUERYED(
          "post",
          "",
          "   WDAnswerRef_aggregate(where: {user: {_eq: " +
            data.data.WDUserConcern[i].beconcernMan +
            "}}) {aggregate {  count    }  }"
        );
        ////被多少人关注
        ///被多少人关注
        let data1 = await QUERYED(
          "post",
          "",
          " WDUserConcern_aggregate(where: {beconcernMan: {_eq: " +
            data.data.WDUserConcern[i].beconcernMan +
            "}}) {  aggregate {   count    } }"
        );
        this.Collectionlisted.push({
          id: data.data.WDUserConcern[i].wdconcernmanid,
          beconcernManS: data.data.WDUserConcern[i].beconcernMan,
          img: this.api.LoginURL.concat(info.data.PCUsers[0].img),
          works: info1.data.WDAnswerRef_aggregate.aggregate.count + "个回答 ",
          beConcernMan:
            "被" +
            data1.data.WDUserConcern_aggregate.aggregate.count +
            "人关注",
          username: info.data.PCUsers[0].name,
          type: "智能问答"
        });
      }
      console.log(this.Collectionlisted);
    },
    ///////////////收藏/////////////////////////////
    async flash() {
      this.show = true;
      this.show_t = false;
      this.article = "";
      this.btnfla = "primary";
      this.Collectionlist = [];
      this.Collectionlists = [];
      this.Collectionlisted = [];
      // 资源交易
      this.ZYCollectionintelligence();
      //知识头条
      this.Collectionlearning();
      //智能问答
      this.Collectionintelligence();
    },
    // 收藏的资源交易
    async ZYCollectionintelligence() {
      ///////资源交易
      ///收藏表
      let res = await QUERYED(
        "post",
        "",
        "  Koleksi(where: {Pengumpul: {_eq: " +
          this.$store.state.userinfo.id +
          "}}) {   beKoleksi   id }"
      );
      if (res.data.Koleksi.length != 0) {
        for (let i = 0; i < res.data.Koleksi.length; i++) {
          console.log(res.data.Koleksi[i].beKoleksi);
          let info = await QUERYED(
            "post",
            "",
            "ZYDocument_ALL(where: {id: {_eq: " +
              res.data.Koleksi[i].beKoleksi +
              "}}){    id      name}"
          );
            this.Collectionlist.push({
              id: res.data.Koleksi[i].id,
              username: info.data.ZYDocument_ALL.length==0?"该资源不存在":info.data.ZYDocument_ALL[0].name,
              beKoleksi: res.data.Koleksi[i].beKoleksi,
              type: "资源交易"
            });
        }
      }
    },
    //////收藏的知识头条
    async Collectionlearning() {
      ///收藏到文章
      let info = await QUERYED(
        "post",
        "",
        "  Collect(where: {user: {_eq: " +
          this.$store.state.userinfo.id +
          "}}) { id title  article}"
      );
      if (info.data.Collect.length != 0) {
        for (let i = 0; i < info.data.Collect.length; i++) {
          this.Collectionlists.push({
            id: info.data.Collect[i].id,
            username: info.data.Collect[i].title,
            article: info.data.Collect[i].article,
            type: "知识头条"
          });
        }
      }
    },
    //////收藏的智能问答
    async Collectionintelligence() {
      ///收藏到问题
      let info = await QUERYED(
        "post",
        "",
        "   WDCollect(where: {user: {_eq: " +
          this.$store.state.userinfo.id +
          "}}) {     id question questionTitle }"
      );
      if (info.data.WDCollect.length != 0) {
        for (let i = 0; i < info.data.WDCollect.length; i++) {
          this.Collectionlisted.push({
            id: info.data.WDCollect[i].id,
            username: info.data.WDCollect[i].questionTitle,
            question: info.data.WDCollect[i].question,
            type: "智能问答"
          });
        }
      }
    }
  }
};
</script>

<style scoped>
.collection {
  font-size: 13px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  padding: 5px 21px 21px 21px;
  border-bottom: solid 1px #e8e8e8;
}
.user {
  height: 20px;
}
.time {
  margin-right: 30px;
  /* border: 1px solid forestgreen; */
}
.el-buttons {
  margin-left: 220px;
}
ul {
  padding: 0px;
}
li {
  /* border: 1px solid gold; */
  /* display: inline-block; */
}
.plcont {
  padding: 20px 20px 19px 30px;
  border-bottom: solid 1px #e8e8e8;
}
.name {
  font-size: 13px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  margin-bottom: 24px;
  /* border: 1px solid firebrick; */
}
.dele {
  float: right;
  /* margin-right: 30px; */
  /* border: 1px solid red; */
}
.num {
  float: right;
}
.plcont img {
  width: 60px;
  height: 60px;
  float: left;
  border-radius: 50%;
  margin-right: 20px;
  /* border: 1px solid red; */
}
.chakans {
  cursor: pointer;
}
.chakans:hover {
  text-decoration: underline;
}
</style>
